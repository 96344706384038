import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'

const _config = {
  baseURL: process.env.BASE_API,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
}

const _axios = axios.create(_config)

_axios.interceptors.request.use(
  config => {
    _showLoading()
    return config
  },
  error => {
    _hideLoading()
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  response => {
    _hideLoading()
    return response.data
  },
  error => {
    _handleError(error)
    _hideLoading()
    return Promise.reject(error)
  }
)

const _showLoading = () => {
  NProgress.start()
}

const _hideLoading = () => {
  NProgress.done()
}

const _handleError = (error) => {
  console.log(error.response)
}

const _mixinUrl = (url, params) => {
  url = url || ''
  params = params || {}
  if(!url) return

  url += url.indexOf('?') > 0 ? '&' : '?'
  url = Object.keys(params).reduce((preVal, curItem) => {
    preVal += `${curItem}=${params[curItem]}&`
    return preVal
  }, url)
  return url.replace(/[&?]$/, '')
}

const _http = {}
_http.all = (urls) => {
  return new Promise((resolve, reject) => {
    axios.all(urls)
      .then(axios.spread(function(...res) {
        resolve(res)
      }))
      .catch(err => reject(err))
  })
}
_http.get = (url, params = {}, config) => {
  return _axios.get(_mixinUrl(url, params), config)
}
_http.post = (url, params = {}, config) => {
  return _axios.post(url, params, config)
}

Plugin.install = function(Vue) {
  Vue.$http = _http
  window.$http = _http
  Object.defineProperties(Vue.prototype, {
    $http: {
      get() {
        return _http
      }
    }
  })
}
Vue.use(Plugin)

export default _http
