<template>
  <div class="nation">
    <div class="title">四川民族总体发展简介</div>
    <!-- <div class="intro" style="text-indent: 2em; margin-bottom: 0.5em">
      四川是一个多民族省份，民族地区面积30.21万平方公里、占全省的62.14%，是全国最大的彝族聚居区、第二大藏族聚居区、唯一的羌族聚居区，全省少数民族人口数为568.82万人。
    </div>
    <div class="intro" style="text-indent: 2em; margin-bottom: 0.5em">
      在浩浩荡荡的历史长河中，各族人民休戚与共、风雨同舟，为巴蜀大地发展进步战天斗地、披荆斩棘，镌刻了不朽的奋斗丰碑。这里生活着汉、彝、藏、羌、回等15个世居民族，还有其他41个民族成分。康定情歌、格萨尔史诗等民族文化绚丽多姿，彝族火把节、广元女儿节等风俗习惯延续至今，展现了各民族文化的相融相通。朱德与格达活佛结下生死之交，刘伯承与小叶丹彝海歃血为盟，“牦牛革命”为处于最艰难时期的红军雪中送炭，留下了不朽的民族佳话和红色印记。
    </div>
    <div class="intro" style="text-indent: 2em">
      四川各族群众筚路蓝缕的奋进历程，是我国民族团结进步事业恢弘历史画卷的生动写照，各民族文化兼收并蓄、经济相互依存、情感相互亲近，像石榴籽一样紧紧抱在一起，不断鼓舞和激励了一代代巴蜀儿女在追梦圆梦道路上百折不挠、勇往直前!
    </div> -->
    <div class="intro" style="text-indent: 2em; margin-bottom: 0.5em">
      四川是一个多民族省份，56个民族在川都有居住，有汉、彝、藏、羌、苗、回、蒙古、傈僳、满、纳西、土家、白、布依、傣、壮等15个世居民族，是全国最大的彝族聚居区、第二大藏族聚居区、唯一的羌族聚居区。全省民族地区幅员面积32.8万平方公里，占全省总面积的67.7%，包括阿坝藏族羌族自治州、甘孜藏族自治州、凉山彝族自治州和北川羌族自治县、峨边彝族自治县、马边彝族自治县，米易、盐边、仁和、平武、石棉、宝兴、汉源、荥经、金口河、兴文、宣汉、古蔺、叙永、珙县、筠连、屏山等16个享受少数民族民族待遇的县（区）及83个民族乡。少数民族人口总数约为568.82万人，占全省6.8%。2021年全省民族自治地方经济总量突破3000亿元，实现地区生产总值3006.8亿元，比上年增长7.3%，是2012年（1587.5亿元）的1.89倍，占全省5.58%，全年农村居民人均可支配收入16541元，增长10.3%；城镇居民人均可支配收入38090元，增长8.2%。
    </div>
    <div
      class="title"
      style="font-size: 0.6rem; cursor: pointer"
      @click="mapBack"
    >
      四川民族村寨分布图
      <img
        src="@/assets/img/update.png"
        style="width: 1.5rem; position: absolute"
        alt=""
      />
    </div>
    <div class="map_content">
      <div id="chartMap"></div>
      <div v-show="clickName !== ''" class="text">
        <div class="btn" @click="mapBack">返回</div>
        <div class="name">{{ clickName }}</div>
      </div>
    </div>
    <div style="height: 1px; width: 100%"></div>
    <el-backtop style="width: 1rem" :right="10" :bottom="50">
      <div
        style="
           {
            height: 100%;
            width: 100%;
          }
        "
      >
        <img style="width: 100%; height: 100%" src="@/assets/img/toTop.png" />
        <div style="text-align: center" class="goTop">回顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import _http from '@/request/http'
import { cityMap } from '@/utils/cityMap'
export default {
  name: 'nation',
  data() {
    const _this = this
    return {
      mapdata: [],
      chart: null,
      clickName: '',
      areamap: {
        马尔康市: '229',
        金川县: '230',
        小金县: '231',
        阿坝县: '232',
        若尔盖县: '233',
        红原县: '234',
        壤塘县: '235',
        汶川县: '236',
        理县: '237',
        茂县: '238',
        松潘县: '239',
        九寨沟县: '240',
        黑水县: '241',
        康定市: '242',
        泸定县: '243',
        丹巴县: '244',
        九龙县: '245',
        雅江县: '246',
        道孚县: '247',
        炉霍县: '248',
        甘孜县: '249',
        新龙县: '250',
        德格县: '251',
        白玉县: '252',
        石渠县: '253',
        色达县: '254',
        理塘县: '255',
        巴塘县: '256',
        乡城县: '257',
        稻城县: '258',
        得荣县: '259',
        西昌市: '260',
        德昌县: '261',
        会理县: '262',
        会东县: '263',
        宁南县: '264',
        普格县: '265',
        布拖县: '266',
        昭觉县: '267',
        金阳县: '268',
        雷波县: '269',
        美姑县: '270',
        甘洛县: '271',
        越西县: '272',
        喜德县: '273',
        冕宁县: '274',
        盐源县: '275',
        木里藏族自治县: '276',
        雁江区: '277',
        安岳县: '278',
        乐至县: '280',
        巴州区: '281',
        恩阳区: '282',
        平昌县: '283',
        通江县: '284',
        南江县: '285',
        雨城区: '286',
        名山区: '287',
        荥经县: '288',
        汉源县: '289',
        石棉县: '290',
        天全县: '291',
        芦山县: '292',
        宝兴县: '293',
        通川区: '294',
        达川区: '295',
        万源市: '296',
        渠县: '297',
        大竹县: '298',
        宣汉县: '299',
        开江县: '300',
        广安区: '301',
        前锋区: '302',
        华蓥市: '303',
        岳池县: '304',
        武胜县: '305',
        邻水县: '306',
        叙州区: '307',
        翠屏区: '308',
        南溪区: '309',
        江安县: '310',
        长宁县: '311',
        高县: '312',
        筠连县: '313',
        珙县: '314',
        兴文县: '315',
        屏山县: '316',
        东坡区: '317',
        彭山区: '318',
        仁寿县: '319',
        丹棱县: '320',
        青神县: '321',
        洪雅县: '322',
        顺庆区: '323',
        高坪区: '324',
        嘉陵区: '326',
        阆中市: '327',
        西充县: '328',
        南部县: '329',
        蓬安县: '330',
        营山县: '331',
        仪陇县: '332',
        '市中区（乐山）': '333',
        沙湾区: '334',
        五通桥区: '335',
        金口河区: '336',
        峨眉山市: '337',
        犍为县: '338',
        井研县: '339',
        夹江县: '340',
        沐川县: '341',
        峨边县: '342',
        马边县: '343',
        市中区: '344',
        东兴区: '345',
        隆昌市: '346',
        资中县: '347',
        威远县: '348',
        船山区: '349',
        安居区: '350',
        射洪市: '351',
        蓬溪县: '352',
        大英县: '353',
        利州区: '354',
        昭化区: '355',
        朝天区: '356',
        旺苍县: '357',
        青川县: '358',
        剑阁县: '359',
        苍溪县: '360',
        涪城区: '361',
        游仙区: '362',
        安州区: '363',
        江油市: '364',
        梓潼县: '365',
        三台县: '366',
        盐亭县: '367',
        平武县: '368',
        北川羌族自治县: '369',
        旌阳区: '370',
        罗江区: '371',
        德阳市: '372',
        广汉市: '373',
        什邡市: '374',
        绵竹市: '375',
        中江县: '376',
        江阳区: '377',
        龙马潭区: '378',
        纳溪区: '379',
        泸县: '380',
        合江县: '381',
        古蔺县: '382',
        叙永县: '383',
        东区: '384',
        西区: '385',
        仁和区: '386',
        米易县: '387',
        盐边县: '388',
        自流井区: '389',
        贡井区: '390',
        大安区: '391',
        沿滩区: '392',
        荣县: '393',
        富顺县: '394',
        '': '395',
        武侯区: '396',
        锦江区: '397',
        青羊区: '398',
        金牛区: '399',
        成华区: '400',
        龙泉驿区: '401',
        温江区: '402',
        新都区: '403',
        青白江区: '404',
        双流区: '405',
        郫都区: '406',
        新津县: '407',
        大邑县: '408',
        金堂县: '409',
        蒲江县: '410',
        都江堰市: '411',
        彭州市: '412',
        邛崃市: '413',
        崇州市: '414',
        简阳市: '415'
      },
      option: {
        backgroundColor: '#f4f4f4',
        title: {
          // text: '四川省',
          target: 'self',
          left: 'center',
          textStyle: {
            color: '#000',
            fontSize: 25,
            fontWeight: 'normal',
            fontFamily: 'Microsoft YaHei'
          },
          subtextStyle: {
            color: '#000',
            fontSize: 13,
            fontWeight: 'normal',
            fontFamily: 'Microsoft YaHei'
          }
        }
      },
      option2: {
        tooltip: {
          show: true,
          confine: true,
          trigger: 'item',
          triggerOn: 'mousemove',
          enterable: true,
          transitionDuration: 1,
          textStyle: {
            color: '#000',
            decoration: 'none'
          },
          position: 'inside',
          formatter: function (params) {
            const area_id = _this.areamap[params.name]
            console.log(area_id)
            console.log('74--->', params)
            let t = ''
            if (params.seriesName !== '四川省') {
              // const url = '/api/web_index/getDocumentsByCity?city=' + params.name
              // const res = await _this.$axios.get(url)
              // const records = res.data.records
              // if (records.length > 0) {
              //   records.forEach(ele => {
              //     console.log('ele--->', ele)
              //     t += '<div class="map_hover_list"><i></i><span @click=\'goVillage(' + ele.id + ')\'>' + ele.title + '</span></div>'
              //   })
              //   t = '<div class="map_hover_content">' + t + '</div>'
              // }
              // return t
              const villageList = params.data.village
              if (villageList.length > 0) {
                villageList.forEach((ele) => {
                  t +=
                    '<div class="map_hover_list"><i></i><span onclick=\'goVillage(' +
                    ele.id +
                    ')\'>' +
                    ele.title +
                    '</span></div>'
                })
                t = '<div class="map_hover_content">' + t + '</div>'
              }
            }
            // t += params.name
            return t
            // console.log('90--->', t)
          }
        }
      }
    }
  },
  mounted() {
    document.oncontextmenu = function () {
      /*阻止浏览器默认弹框*/
      return false
    }
    window.goVillage = (id) => {
      console.log('id--->', id)
      this.$router.push({ path: '/detail', query: { id: id } })
    }
    this.chart = this.$echarts.init(document.getElementById('chartMap'))
    this.chart.off('click')
    this.init()
  },
  methods: {
    // 跳转至文章详情
    goVillage(id) {
      console.log('id--->', id)
      this.$router.push({ path: '/detail', query: { id: id } })
    },
    init() {
      const _this = this
      _http.get('/static/data/sichuan.json').then((data) => {
        for (let i = 0; i < data.features.length; i++) {
          _this.mapdata.push({
            name: data.features[i].properties.name
          })
        }
        //注册地图
        _this.$echarts.registerMap('四川省', data)
        //绘制地图
        _this.renderMap('四川省', _this.mapdata)
      })
    },
    // 渲染地图
    renderMap(map, data) {
      const _this = this
      this.option.series = [
        {
          name: map,
          type: 'map',
          mapType: map,
          roam: false,
          nameMap: {
            sichuan: '四川省'
          },
          label: {
            normal: {
              show: true,
              textStyle: {
                color: '#000',
                fontSize: 13
              }
            },
            emphasis: {
              show: true,
              textStyle: {
                color: '#fff',
                fontSize: 13
              }
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#4bbdd6',
              borderColor: '#fff'
            },
            emphasis: {
              areaColor: '#3574c8'
            }
          },
          data: data
        }
      ]
      // //点击鼠标右键渲染四川地图
      this.chart.setOption(this.option)
      // this.chart.off('contextmenu')
      // this.chart.on('contextmenu', function(params) {
      //   _this.renderMap('四川省', _this.mapdata)
      //   this.clickName = ''
      // })
      //地图点击事件
      this.chart.off('click')
      this.chart.on('click', async function (params) {
        const d = []
        _this.clickName = params.name
        console.log('clickName--->', params)
        const url =
          '/api/web_index/getDocumentsByCity?limit=1000&city=' + params.name
        const res = await _this.$axios.get(url)
        console.log('res--->', res)
        const villageList = res.data.records
        if (cityMap[params.name]) {
          _http
            .get(`/static/data/${cityMap[params.name]}.json`)
            .then((data) => {
              console.log('mapData--->', data)
              _this.$echarts.registerMap(params.name, data)
              for (let i = 0; i < data.features.length; i++) {
                console.log('170--->', data.features[i])
                const a = []
                villageList.forEach((item) => {
                  if (
                    item.city.indexOf(data.features[i].properties.name) !== -1
                  ) {
                    a.push(item)
                  }
                })
                d.push({
                  name: data.features[i].properties.name,
                  village: a
                })
              }
              _this.renderMap(params.name, d)
              console.log('d--->', d)
              _this.chart.setOption(_this.option2)
            })
        }
      })
    },
    mapBack() {
      this.renderMap('四川省', this.mapdata)
      this.clickName = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.nation {
  overflow: hidden;
  .el-backtop {
    background-color: transparent;
    box-shadow: none;
  }
  .goTop {
    height: 14px;
    opacity: 1;
    font-size: 14px;
    font-family: 'MicrosoftYaHei';
    text-align: center;
    color: #999999;
    line-height: 14px;
  }
  .title {
    font-size: 0.8rem;
    font-family: 'MicrosoftYaHei', 'MicrosoftYaHei-Bold';
    font-weight: 700;
    color: #333333;
    letter-spacing: 2px;
    margin: 1rem 0 0.5rem 0;
    text-align: center;
  }
  .intro {
    font-size: 0.36rem;
    font-family: 'MicrosoftYaHeiLight';
    color: #333333;
    line-height: 0.6rem;
    letter-spacing: 2px;
    padding: 0 0.5rem;
  }
  .map_intro {
    font-size: 0.44rem;
    font-family: 'MicrosoftYaHei';
    text-align: center;
  }
  .map_content {
    margin: 0.3rem 0.5rem;
    height: 20rem;
    width: 100%;
    // margin-left: -12%;
    position: relative;
    #chartMap {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .text {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .btn {
        margin: 0.3rem 1.5rem 0.3rem 0;
        font-size: 0.2rem;
        padding: 0.1rem 0.3rem;
        border-radius: 0.8rem;
        border: 0.01rem solid #3bb573;
        color: #3bb573;
        background: transparent;
        cursor: pointer;
      }
      .name {
        writing-mode: vertical-lr;
        font-weight: bold;
        text-align: center;
        font-size: 0.4rem;
        letter-spacing: 0.2rem;
        margin-top: 0.5rem;
        display: inline-table;
        height: calc(100% - 10rem);
      }
    }
  }
}
</style>
